// Copyright The Linux Foundation and each contributor to CommunityBridge.
// SPDX-License-Identifier: MIT

import { License } from './license';

export class Dependency {
    dependencyId: string;
    repositoryId: string;
    name: string;
    version: string;
    issueHighCount: number;
    issueMediumCount: number;
    issueLowCount: number;
    licenses: any[];
    url: string;
    depth: number;
    highlight: boolean;
    dependencies: Dependency[];

    constructor(json: any) {
        this.name = json.depth === 0 ? json['repository-name'] : json['dependency-name'];
        this.version = json['dependency-version'];
        this.issueHighCount = json['issue-high-count'];
        this.issueMediumCount = json['issue-medium-count'];
        this.issueLowCount = json['issue-low-count'];
        this.depth = json.depth;
        this.dependencies = Dependency.buildTree(json.dependencies, json.depth + 1);
        this.licenses = this.getLicenses(json.licenses);
        // this.url = this.snykDetailUrl();
        this.highlight = this.getHighlight();
    }

    static buildTree(dependencies: any[], depth: number): Dependency[] {
        if (!dependencies) {
            return null;
        }

        return dependencies.map(json => {
            Object.assign(json, { depth: depth });
            const dep = new Dependency(json);
            return dep;
        });
    }

    private getLicenses(licenses: string[]): string[] {
        if (!licenses) {
            return [];
        }

        return licenses.filter(x => x);
    }

    // private snykDetailUrl() {
    //     if (this.packageManager && this.name) {
    //         return `https://app.snyk.io/vuln/${this.packageManager}:${encodeURIComponent(this.name)}`;
    //     }
    //     return null;
    // }

    private getHighlight(): boolean {
        if (this.depth > 0) {
            if (this.dependencies) {
                for (const dep of this.dependencies) {
                    if (dep.calcHighlight()) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    private calcHighlight(): boolean {
        if (this.issueLowCount > 0 || this.issueMediumCount > 0 || this.issueHighCount > 0) {
            return true;
        }

        if (this.dependencies) {
            for (const dep of this.dependencies) {
                if (dep.calcHighlight()) {
                    return true;
                }
            }
        }

        return false;
    }
}
